<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button *ngIf="!isModal" defaultHref="/" [text]="'core.backButton' | translate"></ion-back-button>
      <ion-button *ngIf="isModal" (click)="modalController.dismiss()">{{ 'core.done' | translate }}</ion-button>
    </ion-buttons>
    <ion-title>{{ 'campus-navi.title' | translate }}</ion-title>
    <ion-buttons slot="end">
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content forceOverscroll="false">
  <app-map [trackUserLocation]="trackUserLocation" [enabledCategories]="enabledCategories" [showCategories]="showCategories"></app-map>
</ion-content>
