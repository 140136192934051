import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Pageable } from 'src/app/common';
import { AUTHENTICATED } from 'src/app/services/oauth2/authentication.interceptor';
import { environment } from 'src/environments/environment';

export interface ConjectFM {
  id: number;
  conjectFMId: number;
}

export interface AssociatedURL {
  associatedURL: string | null;
  title: string | null;
}

export interface LocationAggregation {
  uuid: string;
  conjectFM: ConjectFM;
  associatedURLs: AssociatedURL[]
}

const API_URL = environment.apiUrl;
// const API_SERVICE_URL = 'http://localhost:8081/location-lookup'
const API_SERVICE_URL = `${API_URL}/location-lookup/v1`;
const API_LOOKUP = `${API_SERVICE_URL}/lookup`;

@Injectable({
  providedIn: 'root'
})
export class LocationLookupService {

  constructor(
    private http: HttpClient
  ) { }

  public locationAggregations(offset: number = 0, limit: number = 10): Observable<Pageable<LocationAggregation[]>> {
    return this.http.get<Pageable<LocationAggregation[]>>(`${API_LOOKUP}?offset=${offset}&limit=${limit}`);
  }

  public locationAggregation(uuid: string): Observable<LocationAggregation> {
    return this.http.get<LocationAggregation>(`${API_LOOKUP}/${uuid}`);
  }

  public createLocationAggregation(locationAggregation: LocationAggregation): Observable<LocationAggregation> {
    return this.http.post<LocationAggregation>(`${API_LOOKUP}`, locationAggregation, { context: AUTHENTICATED });
  }
  
  public deleteLocationAggregation(uuid: string): Observable<void> {
    return this.http.delete<void>(`${API_LOOKUP}/${uuid}`, { context: AUTHENTICATED });
  }

  public updateLocationAggregation(locationAggregation: LocationAggregation): Observable<LocationAggregation> {
    return this.http.put<LocationAggregation>(`${API_LOOKUP}/${locationAggregation.uuid}`, locationAggregation, { context: AUTHENTICATED });
  }

  private toRadians(degrees: number): number {
    return degrees * (Math.PI / 180);
  }

  /**
     * This is basically an implementation of the Haversine formula.
     * <a href="https://en.wikipedia.org/wiki/Haversine_formula">Haversine formula</a>
     * 
     * lat / lng are entered in radians
     *
     * @return distance in meters
     */
  public geocoordinateDistance(latStart: number, lngStart: number, latEnd: number, lngEnd: number, elevationStart: number, elevationEnd: number): number {
    // Radius of the earth in meters
    const earth_radius: number = 6371000;

    const phi_start = this.toRadians(latStart);
    const phi_end = this.toRadians(latEnd);

    const delta_phi = this.toRadians(latEnd - latStart);
    const delta_lambda = this.toRadians(lngEnd - lngStart);

    const a = Math.pow(Math.sin(delta_phi / 2.0), 2) + Math.cos(phi_start) * Math.cos(phi_end) * Math.pow(Math.sin(delta_lambda / 2.0), 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance_meters = earth_radius * c;

    const height = elevationStart - elevationEnd;
    const elevation_distance_meters = Math.sqrt(Math.pow(distance_meters, 2) + Math.pow(height, 2));

    return Math.round(elevation_distance_meters);
  }
}
