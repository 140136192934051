<ion-header>
    <ion-toolbar color="primary">
        <ion-buttons slot="start">
            <ion-button (click)="modalController.dismiss()">{{ 'core.done' | translate }}</ion-button>
        </ion-buttons>
        <ion-title>{{ 'location-lookup.title' | translate }}</ion-title>
    </ion-toolbar>
    <ion-toolbar color="primary" *ngIf="conjectFMRoom?.roomNo">
        <div class="row">
            <div>{{ conjectFMRoom.building.abbreviation }}</div>
            <ion-icon size="small" name="arrow-forward-outline" class="ion-padding-start ion-padding-end"></ion-icon>
            <div>{{ conjectFMRoom.floor.name }}</div>
            <ion-icon size="small" name="arrow-forward-outline" class="ion-padding-start ion-padding-end"></ion-icon>
            <div>{{ conjectFMRoom.roomNo }}</div>
        </div>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-list *ngIf="employees.length">
        <ion-list-header>
            <ion-label>{{ 'location-lookup.employees' | translate }}</ion-label>
        </ion-list-header>
        <ion-item *ngFor="let employee of employees" [detail]="true" (click)="openEmployee(employee)">
            <ion-label>{{ employee.displayName }}</ion-label>
        </ion-item>
    </ion-list>

    <ion-list *ngIf="locationAggregation?.associatedURLs.length">
        <ion-list-header>
            <ion-label>{{ 'location-lookup.aggregatedURLs' | translate }}</ion-label>
        </ion-list-header>
        <ion-item *ngFor="let url of locationAggregation?.associatedURLs" [detail]="true">
            <ion-label (click)="openURL(url.associatedURL)">{{ url.title }}</ion-label>
        </ion-item>
    </ion-list>

    <ion-list *ngIf="nearestDefibrillator">
        <ion-list-header>
            <ion-label>{{ 'location-lookup.nearestDefibrillator' | translate }}</ion-label>
        </ion-list-header>
        <ion-item [detail]="true" (click)="openCampusNavi(1)">
            <ion-icon aria-hidden="true" class="fas fa-heartbeat" slot="start"></ion-icon>
            <ion-label>
                <h2>
                    {{ nearestDefibrillator?.poi?.name[languageCode] }}
                </h2>
                <p [innerHTML]="nearestDefibrillator?.poi?.description[languageCode]"></p>
            </ion-label>
            <ion-badge slot="end">
                {{ nearestDefibrillator.distance > 0 ? nearestDefibrillator.distance + ' m' : '' }}
            </ion-badge>
        </ion-item>
    </ion-list>

    <ion-list *ngIf="nearestFirstAidRoom">
        <ion-list-header>
            <ion-label>{{ 'location-lookup.nearestFirstAidRoom' | translate }}</ion-label>
        </ion-list-header>
        <ion-item [detail]="true" (click)="openCampusNavi(4)">
            <ion-icon aria-hidden="true" class="fas fa-medkit" slot="start"></ion-icon>
            <ion-label>
                <h2>
                    {{ nearestFirstAidRoom?.poi?.name[languageCode] }}
                </h2>
                <p [innerHTML]="nearestFirstAidRoom?.poi?.description[languageCode]"></p>
            </ion-label>
            <ion-badge slot="end">
                {{ nearestFirstAidRoom.distance > 0 ? nearestFirstAidRoom.distance + ' m' : '' }}
            </ion-badge>
        </ion-item>
    </ion-list>
</ion-content>
