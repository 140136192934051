import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

const API_URL = environment.apiUrl;
const API_SERVICE_URL = `${API_URL}/routing/v2`;
const API_ROUTE = `${API_SERVICE_URL}/getPath/{0}/{1}/{2}/{3}`;

@Injectable({
  providedIn: 'root'
})
export class RoutingService {
  constructor(private http: HttpClient, private translate: TranslateService) { }

  public route(startLat: number, startLon: number, endLat: number, endLon: number): Observable<number[][]> {
    return this.http.get<any>(API_ROUTE.format(startLat, startLon, endLat, endLon))
      .pipe(map(paths => {
        const normalizedPaths = [];
        for (const path of paths) {
          normalizedPaths.push([path.longitude, path.latitude]);
        }
        return normalizedPaths;
      }));
  }

  public getLocale(): string {
    switch (this.translate.currentLang) {
      case 'de-DE': {
        return 'de';
      }

      case 'en-US': {
        return 'en';
      }

      default: {
        break;
      }
    }
  }
}
