import { Pipe, PipeTransform } from '@angular/core';

/**
 * Formats telephone numbers to the E.123 ITU-T notiation
 *
 * E.g: +49-231-755-2731 -> +49 231 755 2731
 *
 * https://de.wikipedia.org/wiki/E.123
 */
@Pipe({ name: 'phonenumber' })
export class PhonenumberPipe implements PipeTransform {
  transform(value: string): string {
    if (value == null) {
      return null;
    }
    const parts = value.split('-');
    const highlightLength = parts[parts.length - 1].length;

    const e123Format = value.replace(/-/g, ' ');
    const parsed = `${e123Format.slice(0, -highlightLength)} <strong>${e123Format.slice(-highlightLength)}</strong>`;
    return `<a href="tel:${value}">${parsed}</a>`;
  }
}
