import {
  ToggleCanteenRemember,
  ToggleDepartureRemember,
  TogglePush,
  TogglePushChannel,
  ChangeLanguage,
  ResetSettings,
  ChangeRememberedCanteen,
  ChangeRememberedStation,
  ChangeInvertTabSwipe,
  UpdatePersonalContactDetails, ToggleWeather, ChangeWeatherStyle,
  ChangeWeatherWindUnit
} from './settings.actions';
import { ICanteen } from '../../pages/home/canteen/canteen.service';
import { IStation } from '../../pages/home/departure-monitor/departure-monitor.service';
import { createSelector, createFeatureSelector, Action, createReducer, on } from '@ngrx/store';

export const settingsFeatureKey = 'settings';

export enum IPushChannel {
  GENERAL = 'general',
  SPORTS = 'sports',
  LIBRARY = 'library',
  DE = 'de',
  EN = 'en',
  ANDROID = 'android',
  IOS = 'ios',
}

export enum ILanguage {
  DE = 'de-DE',
  EN = 'en-US',
}

export enum IWeatherStyle {
  GREEN = 'green',
  WHITE = 'white',
  COLORED = 'colored',
}

export enum IWeatherWindUnit {
  M_S = 'm_s',
  KM_H = 'km_h',
  KTS = "kts",
  BFT = 'bft'
}

export interface PushState {
  enabled: boolean;
  channel: { [K in IPushChannel]?: boolean };
}

export interface PersonalContactDetails {
  surname?: string;
  givenname?: string;
  dateOfBirth?: string;
  street?: string;
  zipCode?: string;
  city?: string;
  telephone: string;
}

export interface SettingsState {
  loading: boolean;
  push: PushState;
  language: ILanguage;
  canteen: {
    remember: boolean;
    remembered: ICanteen;
  };
  departureStation: {
    remember: boolean;
    remembered: IStation;
  };
  miscellaneous: {
    invertTabSwipe: boolean;
  };
  personalContactDetails: PersonalContactDetails;
  weather: {
    enabled: boolean;
    style: IWeatherStyle;
    windUnit: IWeatherWindUnit;
  };
}

export const initialState: SettingsState = {
  loading: false,
  language: ILanguage.DE,
  canteen: {
    remember: true,
    remembered: null,
  },
  departureStation: {
    remember: true,
    remembered: null,
  },
  weather: {
    enabled: true,
    style: IWeatherStyle.COLORED,
    windUnit: IWeatherWindUnit.KM_H
  },
  push: {
    enabled: false,
    channel: {
      general: true,
      sports: true,
      library: true,
    },
  },
  miscellaneous: {
    invertTabSwipe: false,
  },
  personalContactDetails: {
    surname: null,
    givenname: null,
    street: null,
    zipCode: null,
    city: null,
    telephone: null,
  },
};

const settingsReducer = createReducer<SettingsState, Action>(
  initialState,
  on(ResetSettings, () => initialState),
  on(ToggleCanteenRemember, (state: SettingsState, action: { enabled: boolean }) => ({
    ...state,
    canteen: { ...state.canteen, remember: action.enabled },
  })),
  on(ToggleDepartureRemember, (state: SettingsState, action: { enabled: boolean }) => ({
    ...state,
    departureStation: { ...state.departureStation, remember: action.enabled },
  })),
  on(TogglePush, (state: SettingsState, action: { enabled: boolean }) => ({ ...state, push: { ...state.push, enabled: action.enabled } })),
  on(TogglePushChannel, (state: SettingsState, action: { channel: IPushChannel; enabled: boolean }) => ({
    ...state,
    push: { ...state.push, channel: { ...state.push.channel, [action.channel]: action.enabled } },
  })),
  on(ToggleWeather, (state: SettingsState, action: { enabled: boolean }) => ({ ...state, weather: { ...state.weather, enabled: action.enabled }})),
  on(ChangeWeatherStyle, (state: SettingsState, action: { style: IWeatherStyle }) => ({ ...state, weather: { ...state.weather, style: action.style } })),
  on(ChangeWeatherWindUnit, (state: SettingsState, action: { windUnit: IWeatherWindUnit }) => ({ ...state, weather: { ...state.weather, windUnit: action.windUnit } })),
  on(ChangeLanguage, (state: SettingsState, action) => ({ ...state, language: action.language })),
  on(ChangeRememberedCanteen, (state: SettingsState, action) => ({
    ...state,
    canteen: {
      remember: state.canteen.remember,
      remembered: action.canteen,
    },
  })),
  on(ChangeRememberedStation, (state: SettingsState, action) => ({
    ...state,
    departureStation: {
      remember: state.departureStation.remember,
      remembered: action.station,
    },
  })),
  on(ChangeInvertTabSwipe, (state: SettingsState, action: { invertTabSwipe: boolean }) => ({
    ...state,
    miscellaneous: {
      invertTabSwipe: action.invertTabSwipe,
    },
  })),
  on(UpdatePersonalContactDetails, (state: SettingsState, action: { personalContactDetails: PersonalContactDetails }) => ({
    ...state,
    personalContactDetails: {
      ...state.personalContactDetails,
      ...action.personalContactDetails,
    },
  }))
);

export const reducer = (state: SettingsState | undefined, action: Action) => settingsReducer(state, action);

export const selectSettings = createFeatureSelector<SettingsState>(settingsFeatureKey);

export const getCanteen = createSelector(selectSettings, (state: SettingsState) => state.canteen);
export const getCanteenRemembered = createSelector(selectSettings, (state: SettingsState) => state.canteen.remembered);
export const getCanteenRemember = createSelector(selectSettings, (state: SettingsState) => state.canteen.remember);

export const getDeparture = createSelector(selectSettings, (state: SettingsState) => state.departureStation);
export const getDepartureRemembered = createSelector(selectSettings, (state: SettingsState) => state.departureStation.remembered);

export const getDepartureRemember = createSelector(selectSettings, (state: SettingsState) => state.departureStation.remember);

export const getPush = createSelector(selectSettings, (state: SettingsState) => state.push);
export const getPushEnabled = createSelector(selectSettings, (state: SettingsState) => state.push.enabled);
export const getPushChannel = createSelector(selectSettings, (state: SettingsState) => state.push.channel);
export const getPushChannelEnabled = (channel: IPushChannel) => createSelector(
  selectSettings,
  (state: SettingsState) => state.push.channel[channel]
);
export const getWeatherEnabled = createSelector(selectSettings, (state: SettingsState) => state.weather.enabled);
export const getWeatherStyle = createSelector(selectSettings, (state: SettingsState) => state.weather.style);
export const getWeatherWindUnit = createSelector(selectSettings, (state: SettingsState) => state.weather.windUnit);

export const getLanguage = createSelector(selectSettings, (state: SettingsState) => state.language);

export const getInvertTabSwipe = createSelector(selectSettings, (state: SettingsState) => state.miscellaneous.invertTabSwipe);

export const getPersonalContactDetails = createSelector(selectSettings, (state: SettingsState) => state.personalContactDetails);
