import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, forkJoin, of, Subscription } from 'rxjs';
import { isNull } from 'src/app/helper-functions';
import { Pageable } from 'src/app/common';
import { AuthenticationService } from 'src/app/services/oauth2/authentication.service';
import { AUTHENTICATED } from 'src/app/services/oauth2/authentication.interceptor';

export interface IEmployeeAddon {
  value: string;
  type: string;
  label?: string;
  publiclyVisible: boolean;
}

export interface IEmployeeExtension {
  descriptionDe: string;
  descriptionEn: string;
  portraitPubliclyVisible: boolean;
  addons: Array<IEmployeeAddon>;
}

export interface IEmployee {
  tudoid: string;
  displayName: string;
  forename: string;
  surname: string;
  email: string;
  primaryPhoneNumber: string;
  primaryFaxNumber: string;
  organizationIds: Array<number>;
  visibleAvatar: boolean;
  employeeExtension?: IEmployeeExtension;
}

export interface IEmployeeNumber {
  type: string;
  e164Num: string;
  buildingShortname: string;
  street: string;
  tudoid: string;
  roomName: string;
  organizationId: string;
  organizationName: string;
}

export interface IPagination {
  offset: number;
  limit: number;
  total: number;
}

export interface IOrganizationNumbers {
  [key: string]: Array<IEmployeeNumber>;
}

export interface IOrganization {
  oid: string;
  description: string;
  departmentDescription: string;
  pOid: string;
}

const API_URL = environment.apiUrl;
const API_SERVICE = `${API_URL}/employee-data/v3`;
const API_EMPLOYEES = `${API_SERVICE}/employees`;
const API_EMPLOYEE = `${API_SERVICE}/employees/{0}`;
const API_NUMBERS = `${API_SERVICE}/employees/{0}/numbers`;
const API_ORGANIZATIONS = `${API_SERVICE}/organizations/{0}`;
// TODO removed "?type=AVATAR" after changing backend to not require it anymore
const API_PORTRAIT = `${API_SERVICE}/employees/{0}/portrait?type=AVATAR`;

@Injectable({
  providedIn: 'root',
})
export class EmployeeService implements OnDestroy {
  private subscriptions = new Subscription();

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
  ) {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public search(query: string | null = null, oid: string | null = null, offset: number = 0, limit: number = 10): Observable<Pageable<IEmployee[]>> {
    const pagination = `&offset=${offset}&limit=${limit}`;
    return this.http.get<Pageable<IEmployee[]>>(`${API_EMPLOYEES}?q=${query}${pagination}`,
      { ...(this.authenticationService.isAuthenticated() ? { context: AUTHENTICATED } : null) });
  }

  public searchByRoomId(roomId: number | null = null, offset: number = 0, limit: number = 10): Observable<Pageable<IEmployee[]>> {
    const pagination = `&offset=${offset}&limit=${limit}`;
    return this.http.get<Pageable<IEmployee[]>>(`${API_EMPLOYEES}?conjectFMRoomId=${roomId}${pagination}`,
      { ...(this.authenticationService.isAuthenticated() ? { context: AUTHENTICATED } : null) });
  }

  public getEmployee(employee: string): Observable<IEmployee> {
    return this.http.get<IEmployee>(API_EMPLOYEE.format(employee),
      { ...(this.authenticationService.isAuthenticated() ? { context: AUTHENTICATED } : null) });
  }

  public getNumbersFrom(employee: string): Observable<Pageable<IEmployeeNumber[]>> {
    return this.http.get<Pageable<IEmployeeNumber[]>>(API_NUMBERS.format(employee));
  }

  public getOrganizations(oids: number[]): Observable<IOrganization[]> {
    if (oids.length <= 0) {
      return of([]);
    }
    return forkJoin(
      oids.map((oid) => this.http.get<IOrganization>(API_ORGANIZATIONS.format(oid)))
    );
  }

  public getPortraitURL(employee: string): string {
    if (isNull(employee)) {
      return null;
    }
    return API_PORTRAIT.format(employee);
  }

  public getAvatar(employee: string): Observable<Blob> {
    const headers = new HttpHeaders({ Accept: 'image/jpg', });
    return this.http.get(this.getPortraitURL(employee), { responseType: 'blob', headers, context: AUTHENTICATED });
  }


  public getPortrait(): Observable<Blob> {
    const headers = new HttpHeaders({ Accept: 'image/jpg', });
    return this.http.get(this.getPortraitURL('me'), { responseType: 'blob', headers, context: AUTHENTICATED });
  }

  public savePortrait(blob: Blob): Observable<void> {
    const headers = new HttpHeaders({});
    const formData = new FormData();
    formData.append('file', blob);
    return this.http.post<void>(API_PORTRAIT.format('me'), formData,{ headers, context: AUTHENTICATED });
  }

  public deletePortrait(): Observable<void> {
    const headers = new HttpHeaders({});
    return this.http.delete<void>(API_PORTRAIT.format('me'),{ headers, context: AUTHENTICATED });
  }

  public saveEmployeeData(employee: IEmployee): Observable<void> {
    const headers = new HttpHeaders({});
    return this.http.post<void>(API_EMPLOYEE.format('me'), employee, { headers, context: AUTHENTICATED });
  }
}
