import { Injectable } from '@angular/core';
import { IPOI, IPOICategory, POIService } from './poi.service';
import { ConjectFMService, IRoom } from './conjectfm.service';
import { Observable, map, switchMap, tap } from 'rxjs';
import { findIconDefinition, library, parse } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor(
    private poiService: POIService,
    private conjectFMService: ConjectFMService
  ) {
    library.add(fas);
  }

  public geoJSONFeatureCollection(pois: IPOI[], categories: IPOICategory[] = []): GeoJSON.FeatureCollection {
    const featureCollection: GeoJSON.FeatureCollection = {
      type: 'FeatureCollection',
      features: []
    }

    if(categories?.length !== 0) {
      for(const category of categories) {
        const categoryPOIs = pois.filter(poi => poi.categoryId === category.id);

        // All POIs that have a room / building attached to it are in this map (`buildingId` -> `IPOI[]`)
        const poisByBuildingId: { [key: number]: IPOI[] } = {};

        // POIs that does not have a room / building attached to it
        const otherPOIs: IPOI[] = []

        categoryPOIs.forEach(poi => {
          if(poi.room) {
            if(poi.room.buildingId in poisByBuildingId) {
              poisByBuildingId[poi.room.buildingId].push(poi);
            } else {
              poisByBuildingId[poi.room.buildingId] = [poi];
            }
          } else {
            otherPOIs.push(poi);
          }
        })
        
        // Construct building pois as features
        for(const buildingId in poisByBuildingId) {
          let name = null;
          let coordinates = null;
          let description: {de: String; en: String}[] = [];
          let rooms: IRoom[] = [];
          for(const buildingPOI of poisByBuildingId[buildingId]) {
            coordinates = [buildingPOI.gps.longitude, buildingPOI.gps.latitude];

            if(!name) {
              name = buildingPOI.name;
            }

            description.push(buildingPOI.description);
            rooms.push(buildingPOI.room);
          }
          const feature: GeoJSON.Feature = {
            id: buildingId,
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates
            },
            properties: {
              name,
              description,
              category,
              coordinates,
              icon: this.fontAwesomeCharacterCode(category.icon),
              rooms
            }
          }
          featureCollection.features.push(feature);
        }
        
        for(const poi of otherPOIs) {
          const feature: GeoJSON.Feature = {
            id: poi.id,
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [poi.gps.longitude, poi.gps.latitude]
            },
            properties: {
              name: poi.name,
              description: poi.description,
              category,
              coordinates: [poi.gps.longitude, poi.gps.latitude],
              occupancyLevel: poi.occupancyLevel,
              icon: this.fontAwesomeCharacterCode(category.icon)
            }
          }
          featureCollection.features.push(feature);
        }
      }
    }
    return featureCollection;
  }
  
  /**
   * This function constructs a list of all Points of Interest with detailed room information from ConjectFM.
   * 
   * @returns A list of IPOI's
   */
  public detailedPOIs(): Observable<IPOI[]> {
    return this.poiService.pois().pipe(
      switchMap((pois: IPOI[]) => {
        const roomIds = pois.filter(poi => !!poi.roomId).map(poi => poi.roomId);
        return this.conjectFMService.rooms(roomIds, true).pipe(map((rooms: IRoom[]) => {
          pois.forEach(poi => {
            poi.room = rooms.find(room => room.id === poi.roomId);
          });
          return pois;
        }));
      }),
      tap((pois) => {
        // Map POIs without a category to categoryId 0. This is safe since there will never be a category with the id 0 in the database.
        pois.forEach((poi) => {
          if(poi.categoryId === null) {
            poi.categoryId = 0;
          }
        });
      })
    );
  }

  public fontAwesomeCharacterCode(iconName: string): string {
    const parseIcon = parse.icon(iconName);
    const icon = findIconDefinition(parseIcon);
    return String.fromCharCode(parseInt(icon.icon[3], 16));
  }
}
