import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LocationAggregation, LocationLookupService } from './location-lookup.service';
import { forkJoin, Subject, Subscription, switchMap, take } from 'rxjs';
import { ConjectFMService, IRoom } from 'src/app/components/map/conjectfm.service';
import { EmployeeService, IEmployee } from '../services/employee.service';
import { Pageable } from 'src/app/common';
import { Browser } from '@capacitor/browser';
import { IPOI, POIService } from 'src/app/components/map/poi.service';
import { TranslateService } from '@ngx-translate/core';
import { EmployeeDetailPage } from '../home/employee-search/detail/employee-detail.page';
import { CampusNaviPage } from '../home/campus-navi/campus-navi.page';

interface LocationInput {
    uuid: string;
}

@Component({
  selector: 'app-location-lookup',
  templateUrl: './location-lookup.page.html',
  styleUrls: [
    './location-lookup.page.scss'
  ]
})
export class LocationLookupPage implements OnInit, OnDestroy {
    public languageCode = 'de';

    @Input()
    public locationInput: LocationInput | null = null;
    
    public locationAggregation: LocationAggregation | null = null;
   
    public nearestDefibrillator: { distance: number, poi: IPOI } | null = null;
    public nearestFirstAidRoom: { distance: number, poi: IPOI } | null = null;

    private conjectFMId$: Subject<number> = new Subject();
    public conjectFMRoom: IRoom | null = null;
    public conjectFMRoom$: Subject<IRoom> = new Subject();
    public employees: IEmployee[] = [];
    private subscriptions = new Subscription();

    constructor(
      public modalController: ModalController,
      public translate: TranslateService,
      private locationLookupService: LocationLookupService,
      private conjectFMService: ConjectFMService,
      private employeeDataService: EmployeeService,
      private poiService: POIService,
    ) {}

    public ngOnInit() {
      this.languageCode = this.translate.currentLang.split('-')[0] ?? 'de';

      this.locationLookupService.locationAggregation(this.locationInput.uuid).subscribe((locationAggregation: LocationAggregation) => {
        this.locationAggregation = locationAggregation;

        if(locationAggregation?.conjectFM?.conjectFMId) {
          this.conjectFMId$.next(locationAggregation.conjectFM.conjectFMId);
        }
      });

      this.subscriptions.add(
        this.conjectFMId$.subscribe((conjectFMId) => {
          this.conjectFMService.room(conjectFMId, true).subscribe((conjectFMRoom) => {
            this.conjectFMRoom$.next(conjectFMRoom);
            this.conjectFMRoom = conjectFMRoom;
          })
        })
      );

      this.subscriptions.add(
        forkJoin({
          defibrillators: this.poiService.poisByCategory(1),
          firstAidRooms: this.poiService.poisByCategory(4),
          conjectFMRoom: this.conjectFMRoom$.pipe(take(1))
        }).subscribe(data => {
          this.nearestDefibrillator = this.nearestPOI(data.defibrillators, data.conjectFMRoom);
          this.nearestFirstAidRoom = this.nearestPOI(data.firstAidRooms, data.conjectFMRoom);
        })
      );

      this.subscriptions.add(
        this.conjectFMId$.pipe(
          switchMap((conjectFMId: number) => {
            return this.employeeDataService.searchByRoomId(conjectFMId);
          })
        ).subscribe((employees: Pageable<IEmployee[]>) => {
          this.employees = employees.data;
        })
      )
    }

    public nearestPOI(pois: IPOI[], room: IRoom): { distance: number, poi: IPOI } {
      let nearest: { distance: number, poi: IPOI} | null = null;

      for(let poi of pois) {
        const distance = this.locationLookupService.geocoordinateDistance(room.building.gps.latitude, room.building.gps.longitude, poi.gps.latitude, poi.gps.longitude, 0, 0);
        if(nearest === null || distance < nearest.distance) {
          nearest = { distance, poi };
        }
      }

      return nearest;
    }

    public async openEmployee(employee: IEmployee) {
      const modal = await this.modalController.create({
        component: EmployeeDetailPage,
        componentProps: {
          employee
        }
      })
      modal.present();
    }

    public async openCampusNavi(categoryId: number) {
      const modal = await this.modalController.create({
        component: CampusNaviPage,
        id: 'campusNavi',
        componentProps: {
          enabledCategories: [categoryId],
          showCategories: false,
          isModal: true,
        }
      });
      await modal.present();
    }

    public ngOnDestroy(): void {
      this.subscriptions.unsubscribe();
    }

    public openURL(url: string) {
      Browser.open({ url });
    }
}
