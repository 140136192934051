<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button *ngIf="!isModal" defaultHref="/home/employee-search" [text]="'core.backButton' | translate"></ion-back-button>
      <ion-button *ngIf="isModal" (click)="modalController.dismiss()">{{ 'core.done' | translate }}</ion-button>
    </ion-buttons>
    <ion-title>{{ employee.displayName }}</ion-title>
    <ion-buttons slot="end">
      <ion-button slot="icon-only" (click)="addToContacts()">
        <ion-icon name="person-add" aria-labelledby="add-to-contacts-label"></ion-icon>
        <span id="add-to-contacts-label" style="display:none">{{ 'employee-search.addToAddressbook' | translate}}</span>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-thumbnail class="ion-text-center">
    <img [src]="avatar" />
  </ion-thumbnail>

  <ion-list>
    <ion-list-header>
      <ion-label>
        <ion-icon name="briefcase" slot="start" aria-hidden="true"></ion-icon>
        {{ 'employee-search.coreData' | translate }}
      </ion-label>
    </ion-list-header>
    <ion-item>
      <ion-icon name="at" slot="start" aria-hidden="true"></ion-icon>
      <ion-label>
        <h3>
          {{ 'employee-search.email' | translate }}
        </h3>
        <h2>
          <a [href]="'mailto:' + employee.email">{{ employee.email }}</a>
        </h2>
      </ion-label>
    </ion-item>
  </ion-list>

  <ion-list *ngFor="let organization of organizationNumbers | keyvalue">
    <ion-list-header>
      <ion-label>
        <ion-icon name="briefcase" slot="start" aria-hidden="true"></ion-icon>
        <span *ngIf="organization.key !== 'other'">
          {{ organization.key }}
        </span>
        <span *ngIf="organization.key === 'other'">
          {{ 'employee-search.additionalNumber' | translate }}
        </span>
      </ion-label>
    </ion-list-header>
    <ion-item *ngFor="let number of organization.value">
      <ion-icon *ngIf="number.type === 'ext'" name="call" slot="start" aria-hidden="true"></ion-icon>
      <ion-icon *ngIf="number.type === 'fax'" name="print" slot="start" aria-hidden="true"></ion-icon>
      <ion-icon *ngIf="number.type === 'sek'" name="home" slot="start" aria-hidden="true"></ion-icon>
      <ion-label>
        <h3>
          <span *ngIf="number.type === 'ext'">{{ 'employee-search.telephone' | translate }}</span>
          <span *ngIf="number.type === 'fax'">{{ 'employee-search.facsimile' | translate }}</span>
          <span *ngIf="number.type === 'sek'">{{ 'employee-search.administration' | translate }}</span>
        </h3>
        <h2>
          <span [innerHTML]="number.e164Num | phonenumber"></span>
        </h2>
        <h4 *ngIf="number.street && number.roomName">
          {{ number.street }}, Raum {{ number.roomName }}
        </h4>
      </ion-label>
    </ion-item>
  </ion-list>

  <ion-list *ngIf="organizations && organizations.length > 0">
    <ion-list-header>
      <ion-label>
        <ion-icon name="briefcase" slot="start" aria-hidden="true"></ion-icon>
        {{ 'employee-search.orgUnit' | translate }}
      </ion-label>
    </ion-list-header>
    <ion-item *ngFor="let ou of organizations">
      <ion-label class="ion-text-wrap">
        <h2 *ngIf="ou.departmentDescription !== ou.description">{{ ou.departmentDescription }}</h2>
        <h3>{{ ou.description }}</h3>
      </ion-label>
    </ion-item>
  </ion-list>

  <ion-list *ngIf="employee.employeeExtension && employee.employeeExtension.addons && employee.employeeExtension.addons.length">
    <ion-list-header>
      <ion-label>
        <ion-icon name="contact" slot="start" aria-hidden="true"></ion-icon>
        {{ 'employee-search.additionalContactOptions' | translate }}
      </ion-label>
    </ion-list-header>

    <span *ngFor="let addon of employee.employeeExtension.addons">
      <span *ngIf="addon.type === 'HOMEPAGE'; then addonLink; else noAddonLink"></span>

      <ng-template #addonLink>
        <ion-item (click)="openURL(addon.value)" detail="true">
          <ion-icon name="link" slot="start" aria-hidden="true"></ion-icon>
          <ion-label class="ion-text-wrap">
            <h3>
              {{ "employee-search.homepage" | translate }} {{ addon.label }}
            </h3>
            <h3 [innerHTML]="addon.value"></h3>
          </ion-label>
        </ion-item>
      </ng-template>
      <ng-template #noAddonLink>
        <ion-item>
          <ion-icon *ngIf="addon.type === 'MOBILE'" name="phone-portrait" slot="start" aria-hidden="true"></ion-icon>
          <ion-icon *ngIf="addon.type === 'SKYPE'" name="logo-skype" slot="start" aria-hidden="true"></ion-icon>
          <ion-icon *ngIf="addon.type === 'CUSTOM'" name="chatbubbles" slot="start" aria-hidden="true"></ion-icon>
          <ion-label>
            <h3>
              <span *ngIf="addon.type === 'SKYPE'">{{ "employee-search.skype" | translate }}</span>
              <span *ngIf="addon.type === 'MOBILE'">{{ "employee-search.mobile" | translate }}</span>
              {{ addon.label }}
            </h3>
            <h2 [innerHTML]="addon.value"></h2>
          </ion-label>
        </ion-item>
      </ng-template>
    </span>
  </ion-list>

  <ion-list *ngIf="employee.employeeExtension && (employee.employeeExtension.descriptionDe || employee.employeeExtension.descriptionEn)">
    <ion-list-header>
      <ion-label>
        <ion-icon name="contact" slot="start" aria-hidden="true"></ion-icon>
        {{ 'employee-search.description' | translate }}
      </ion-label>
    </ion-list-header>
    <ion-item lines="none">
      <ion-label class="ion-text-wrap">
        <p *ngIf="translate.currentLang === 'de-DE'" class="description" [innerHTML]="employee.employeeExtension.descriptionDe"></p>
        <p *ngIf="translate.currentLang === 'en-US'" class="description" [innerHTML]="employee.employeeExtension.descriptionEn"></p>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
