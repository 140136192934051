import { Component, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EventData, MapComponent as NgxMapComponent } from '@maplibre/ngx-maplibre-gl';
import { GeoJSONSource, GeoJSONSourceOptions, LngLat, Map, MapGeoJSONFeature, MapMouseEvent, Marker } from 'maplibre-gl';
import { MapService } from './map.service';
import { IPOI, IPOICategory, POIService } from './poi.service';
import { BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { Point } from 'geojson';
import { TranslateService } from '@ngx-translate/core';
import { IRoom } from './conjectfm.service';
import { Geolocation } from '@capacitor/geolocation';
import { RoutingService } from './routing.service';
import { AlertController, IonicSafeString, LoadingController, ModalController, NavController } from '@ionic/angular';
import { Clipboard } from '@capacitor/clipboard';
import { OverlayEventDetail } from '@ionic/core';

export interface CustomMapGeoJSONFeature {
  coordinates: number[],
  name: { de: string, en: string},
  description: { de: string, en: string } | { de: string, en: string }[],
  rooms: IRoom[]
  occupancyLevel: 'HIGH' | 'MEDIUM' | 'LOW',
  category: IPOICategory
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {
  public INITIAL_MODAL_BEAKPOINT = 0.4;

  @Input()
  public trackUserLocation = false;

  @Input()
  public enabledCategories: number[] = [];

  @Input()
  public showCategories = false;

  @Input()
  public showSearch = true;

  public categories: IPOICategory[] = [];
  public selectedPOI: CustomMapGeoJSONFeature = null;
  public featureCollection: GeoJSON.FeatureCollection = null;
  public languageCode: string = 'de';
  public loadingCurrentPosition = false;
  
  @ViewChild(NgxMapComponent)
  private mapComponent: NgxMapComponent;
  
  private mapLoaded$ = new Subject<Map>();
  public loaded = new BehaviorSubject<boolean>(false);
  private pois: IPOI[] = [];
  private startMarker: Marker = null;

  constructor(
    private navController: NavController,
    private mapService: MapService,
    private poiService: POIService,
    private translate: TranslateService,
    private routingService: RoutingService,
    private modalController: ModalController,
    private alertController: AlertController,
    private zone: NgZone,
    private loadingController: LoadingController
  ) {}

  public async ngOnInit() {
    const spinner = await this.loadingController.create({
      backdropDismiss: true
    });
    spinner.onDidDismiss().then(async (overlayDetail: OverlayEventDetail) => {
      if(overlayDetail.role === 'error') {
        this.navController.navigateBack('/home');
        const alert = await this.alertController.create({
          header: this.translate.instant('core.errors.serviceError.header'),
          message: this.translate.instant('core.errors.serviceError.message'),
          buttons: ['OK']
        });
        alert.present();
      }
    });
    spinner.present();
    this.languageCode = this.translate.currentLang.split('-')[0] ?? 'de';
    forkJoin([
      this.poiService.categories(),
      this.mapService.detailedPOIs(),
      this.mapLoaded$
    ]).subscribe(([categories, pois, _]) => {
      this.categories = categories;
      this.pois = pois;
      this.featureCollection = this.mapService.geoJSONFeatureCollection(this.pois, this.categories);
      this.loaded.next(true);
      this.loaded.complete();
    });

    this.setup();
  }

  public async ngOnDestroy() {
    // The modal view will hardly ever be dismissed at this point. It'll most likely be dismissed in the encapsulating
    // Ionic Page (e.g. campus-navi or study-areas) since its a better user experience to dissmiss the modal on ionViewWillLeave.
    this.modalController.dismiss(undefined, undefined, 'map-detail-view');
  }

  public async mapLoaded(map: Map) {   
    this.mapLoaded$.next(map);
    this.mapLoaded$.complete();
    this.loadingController.dismiss();
  }

  public mapError(_: ErrorEvent & EventData) {
    this.loadingController.dismiss(null, 'error');
  }

  private setup() {
    this.loaded.subscribe((loaded) => {
      if(loaded) {
        this.setupPOIs();
      }
    });
  }

  public updateFilter(categoryIds: number[]) {
    this.enabledCategories = categoryIds;
    ((this.mapComponent.mapInstance.getSource('poi-source') as GeoJSONSource).workerOptions as GeoJSONSourceOptions).filter = ['in', ['get', 'id', ['get', 'category']], ['literal', categoryIds.length ? categoryIds : null]];
    (this.mapComponent.mapInstance.getSource('poi-source') as GeoJSONSource)._updateWorkerData();
  }

  private setupPOIs() {
    this.mapComponent.mapInstance.addSource(`poi-source`, {
      type: 'geojson',
      data: this.featureCollection,
      cluster: true,
      clusterRadius: 50,
    });

    if(this.enabledCategories.length) {
      this.updateFilter(this.enabledCategories);
    }

    this.mapComponent.mapInstance.addLayer({
      id: `pois-cluster`,
      type: 'circle',
      source: 'poi-source',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': '#639a00',
        'circle-radius': 18,
        'circle-stroke-color': '#000000',
        'circle-stroke-opacity': 0.5,
        'circle-stroke-width': 2
      }
    });

    this.mapComponent.mapInstance.addLayer({
      id: `pois-cluster-counter`,
      type: 'symbol',
      source: 'poi-source',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count}',
        'text-font': ['Noto Sans Bold']
      },
      paint: {
        'text-color': '#FFFFFF',
      }
    });

    // Zoom into map on cluster click
    this.mapComponent.mapInstance.on('click', `pois-cluster`, (clickEvent: any) => {
      this.zone.run(() => {
        this.selectedPOI = null;
      });

      const features = this.mapComponent.mapInstance.queryRenderedFeatures(clickEvent.point, {
          layers: [`pois-cluster`]
      });
      const clusterId = features[0].properties.cluster_id;
      (this.mapComponent.mapInstance.getSource('poi-source') as GeoJSONSource).getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
              if (err) return;
              this.mapComponent.mapInstance.easeTo({
                center: new LngLat((features[0].geometry as GeoJSON.Point).coordinates[0], (features[0].geometry as GeoJSON.Point).coordinates[1]),
                zoom
              });
          }
      );
    });

    for(const category of this.categories.filter(category => this.enabledCategories.includes(category.id))) {
      this.addCategoryLayer(category.id);
    }
  }

  public updateCategoryState(state: { id: number, enabled: boolean }) {
    if(state.enabled) {
      if(
        !this.mapComponent.mapInstance.getLayer(`pois-${state.id}`)
        && !this.mapComponent.mapInstance.getLayer(`pois-symbol-${state.id}`))
      this.addCategoryLayer(state.id)
      this.enabledCategories.push(state.id);
    } else {
      if(this.mapComponent.mapInstance.getLayer(`pois-${state.id}`)) {
        this.mapComponent.mapInstance.removeLayer(`pois-${state.id}`);
      }

      if(this.mapComponent.mapInstance.getLayer(`pois-symbol-${state.id}`)) {
        this.mapComponent.mapInstance.removeLayer(`pois-symbol-${state.id}`);
      }
      this.enabledCategories = this.enabledCategories.filter(categoryId => categoryId !== state.id);
    }
  }

  private addCategoryLayer(categoryId: number): void {
    this.mapComponent.mapInstance.addLayer({
      id: `pois-${categoryId}`,
      type: 'circle',
      source: 'poi-source',
      filter: ['all', ['!', ['has', 'point_count']], ['==', ['get', 'id', ['get', 'category']], categoryId]],
      paint: {
        'circle-color': '#639a00',
        'circle-radius': 20,
        'circle-stroke-width': [
          'case',
          ['==', ['has', 'occupancyLevel'], true],
          7.5,
          0
        ],
        'circle-stroke-opacity': [
          'case',
          ['==', ['has', 'occupancyLevel'], true],
          0.4,
          1
        ],
        'circle-stroke-color': [
          'case',
          ['==', ['get', 'occupancyLevel'], 'LOW'],
          '#639a00',
          ['==', ['get', 'occupancyLevel'], 'MEDIUM'],
          'orange',
          ['==', ['get', 'occupancyLevel'], 'HIGH'],
          'red',
          'black'
        ],
      }
    });

    // Add category icon on POI
    this.mapComponent.mapInstance.addLayer({
      id: `pois-symbol-${categoryId}`,
      type: 'symbol',
      source: 'poi-source',
      filter: ['all', ['!', ['has', 'point_count']], ['==', ['get', 'id', ['get', 'category']], categoryId]],
      layout: {
        'text-font': ['Font Awesome 6 Free Solid'],
        'text-field': ['get', 'icon'],
      },
      paint: {
        'text-color': '#FFFFFF'
      }
    });

    this.mapComponent.mapInstance.on('click', `pois-${categoryId}`, async (mapMouseEvent: MapMouseEvent & { features?: MapGeoJSONFeature[]; }) => {
      this.selectedPOI = null;
      const customFeature = MapComponent.convertFromMapGeoJSONFeatures(mapMouseEvent.features[0]);
      const modal = await this.modalController.getTop();
      if(modal) {
        modal.setCurrentBreakpoint(this.INITIAL_MODAL_BEAKPOINT);  
      }

      this.mapComponent.mapInstance.flyTo({
        center: mapMouseEvent.lngLat,
        offset: [0, -this.offsetBasedOnModal()]
      });
      this.zone.run(() => {
        this.selectedPOI = customFeature;
      });
    });

    this.mapComponent.mapInstance.on('mouseenter', `pois-${categoryId}`, () => {
      this.mapComponent.mapInstance.getCanvas().style.cursor = 'pointer';
    });

    this.mapComponent.mapInstance.on('mouseleave', `pois-${categoryId}`, () => {
      this.mapComponent.mapInstance.getCanvas().style.cursor = '';
    });
  }

  private offsetBasedOnModal(): number {
    const tabbarHeight = document.getElementById("tabbar")?.offsetHeight ?? 0;
    return ((this.mapComponent.mapContainer.nativeElement.offsetHeight - tabbarHeight) * this.INITIAL_MODAL_BEAKPOINT) / 2;
  }

  public static convertFromMapGeoJSONFeatures(features: MapGeoJSONFeature): CustomMapGeoJSONFeature {
    const coordinates = (features.geometry as Point).coordinates.slice()
    const name = JSON.parse(features.properties.name) ?? null;
    const description = features.properties.description ? JSON.parse(features.properties.description) : null;
    const rooms: IRoom[] = features.properties.rooms ? JSON.parse(features.properties.rooms) : null;
    const occupancyLevel: 'LOW' | 'MEDIUM' | 'HIGH' = features.properties.occupancyLevel;
    const category: IPOICategory = features.properties.category ? JSON.parse(features.properties.category) : null;

    const sortedRooms = rooms ? rooms.sort(POIService.sortRoomByFloorAndRoomNo) : null;

    return {
      coordinates,
      name,
      description,
      rooms: sortedRooms,
      occupancyLevel,
      category
    }
  }

  public async addRoute(coordinates: number[]) {
    this.loadingCurrentPosition = true;
    const currentPosition = await Geolocation.getCurrentPosition({ enableHighAccuracy: true, maximumAge: 2000 });

    if(!currentPosition) {
      this.loadingCurrentPosition = false;
      return;
    }

    this.routingService.route(coordinates[1], coordinates[0], currentPosition.coords.latitude, currentPosition.coords.longitude).subscribe(async (path: number[][]) => {
      this.loadingCurrentPosition = false;
      const routingFeature = this.geoJSONFeatureFromPath(path);
      const startFeature = this.geoJSONFeatureFromPath([[currentPosition.coords.longitude, currentPosition.coords.latitude], path[path.length - 1]]);
      const endFeature = this.geoJSONFeatureFromPath([[coordinates[0], coordinates[1]], path[0]]);
      this.exitNavigation();

      this.mapComponent.mapInstance.addSource('route', {
        type: 'geojson',
        data: routingFeature
      });

      this.mapComponent.mapInstance.addSource('startRoute', {
        type: 'geojson',
        data: startFeature
      });

      this.mapComponent.mapInstance.addSource('endRoute', {
        type: 'geojson',
        data: endFeature
      });

      this.mapComponent.mapInstance.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#eb445a',
          'line-width': 6,
        }
      });

      this.mapComponent.mapInstance.addLayer({
        id: 'startRoute',
        type: 'line',
        source: 'startRoute',
        layout: {
          'line-cap': 'round'
        },
        paint: {
          'line-width': 4,
          'line-dasharray': [2, 2],
        }
      });

      this.mapComponent.mapInstance.addLayer({
        id: 'endRoute',
        type: 'line',
        source: 'endRoute',
        layout: {
          'line-cap': 'round'
        },
        paint: {
          'line-width': 4,
          'line-dasharray': [2, 2]
        }
      });

      if(!this.startMarker) {
        this.startMarker = new Marker({ color: '#1da1f2' });
      }
      this.startMarker.remove();
      const currentPositionLngLat = new LngLat(currentPosition.coords.longitude, currentPosition.coords.latitude);
      this.startMarker.setLngLat(currentPositionLngLat).addTo(this.mapComponent.mapInstance);

      this.mapComponent.mapInstance.flyTo({
        center: currentPositionLngLat,
      });
      const modal = await this.modalController.getTop();
      if(modal) {
        modal.dismiss();
      }
    });
  }

  public async copyCoordinates(poi: CustomMapGeoJSONFeature) {
    Clipboard.write({
      string: `${poi.coordinates[1]} ${poi.coordinates[0]}`
    });

    const alert = await this.alertController.create({
      header: this.translate.instant('map.copyCoordinates.header'),
      message: new IonicSafeString(this.translate.instant('map.copyCoordinates.message', { name: poi.name[this.languageCode] })),
      buttons: ['OK']
    });
    alert.present();
  }

  public openFeature(feature: CustomMapGeoJSONFeature) {
    if(feature.category.id) {
      this.updateCategoryState({ id: feature.category.id, enabled: true });
      this.updateFilter([...this.enabledCategories, feature.category.id]);
    }
    
    this.selectedPOI = feature
    this.mapComponent.mapInstance.flyTo({ 
      center: new LngLat(this.selectedPOI.coordinates[0], this.selectedPOI.coordinates[1]),
      zoom: 18,
      offset: [0, -this.offsetBasedOnModal()]
    });
  }

  public isNavigating(): boolean {
    return !!this.mapComponent && !!this.mapComponent.mapInstance && !!this.mapComponent.mapInstance.getLayer("route");
  }

  public exitNavigation() {
    const startRoute = this.mapComponent.mapInstance.getLayer("startRoute");
    const endRoute = this.mapComponent.mapInstance.getLayer("endRoute");
    const route = this.mapComponent.mapInstance.getLayer("route");

    if(startRoute) {
      this.mapComponent.mapInstance.removeLayer("startRoute");
      this.mapComponent.mapInstance.removeSource("startRoute");
    }
    if(endRoute) {
      this.mapComponent.mapInstance.removeLayer("endRoute");
      this.mapComponent.mapInstance.removeSource("endRoute");
    }
    if(route) {
      this.mapComponent.mapInstance.removeLayer("route");
      this.mapComponent.mapInstance.removeSource("route");
    }

    if(this.startMarker) {
      this.startMarker.remove();
    }
  }

  private geoJSONFeatureFromPath(path: number[][]): GeoJSON.Feature {
    return {
      type: 'Feature',
          properties: {},
          geometry: {
              type: 'LineString',
              coordinates: path
          }
    };
  }
}
