<mgl-map
    [style]="'https://mobil.itmc.tu-dortmund.de/mvt/style.json'"
    [zoom]="[14]"
    [center]="[7.4128, 51.4875]"
    [maxZoom]="20"
    [minZoom]="12"
    (mapLoad)="mapLoaded($event)"
    (mapError)="mapError($event)"
    [class.hidden]="(this.loaded | async) === false"
    >
  <mgl-control mglNavigation position="top-right" [visualizePitch]="true"></mgl-control>
  <app-map-control-category *ngIf="showCategories" position="bottom-right" [categories]="categories" (stateChange)="updateCategoryState($event)" (enabledCategoryIds)="updateFilter($event)" [enabledCategories]="enabledCategories"></app-map-control-category>
  <app-map-control-geolocation [trackUserLocation]="true" position="bottom-right"></app-map-control-geolocation>
  <app-map-control-home position="bottom-right"></app-map-control-home>
  <app-map-control-search *ngIf="showSearch && featureCollection !== null" position="bottom-right" [features]="featureCollection.features" (selectedFeature)="openFeature($event)"></app-map-control-search>
</mgl-map>

<div class="navigation-exit">
  <ion-button *ngIf="isNavigating()" color="danger" (click)="exitNavigation()">Navigation beenden</ion-button>
</div>

<ion-modal id="map-detail-view" [isOpen]="!!selectedPOI" [initialBreakpoint]="INITIAL_MODAL_BEAKPOINT" [breakpoints]="[0, 0.17, INITIAL_MODAL_BEAKPOINT, 0.6, 0.9]" (didDismiss)="this.selectedPOI = null" [showBackdrop]="false" [backdropBreakpoint]="0.6">
  <ng-template>
    <ion-content class="ion-padding detail-view">
      <div class="buttons-row ion-padding-bottom">
        <div class="buttons">
          <ion-button *ngIf="selectedPOI" shape="round" (click)="addRoute(selectedPOI.coordinates)" mode="ios">
            <ion-icon slot="start" name="analytics-outline"></ion-icon>
            Route
            <ion-spinner *ngIf="loadingCurrentPosition" name="crescent" class="ion-margin-start"></ion-spinner>
          </ion-button>
          <ion-button shape="round" color="light" (click)="copyCoordinates(selectedPOI)" mode="ios">
            <ion-icon slot="start" name="clipboard-outline"></ion-icon>
            {{ 'map.copyCoordinates.buttonText' | translate }}
          </ion-button>
        </div>
  
        <div class="close-button ion-padding-start">
          <button (click)="this.selectedPOI = null">
            <ion-icon name="close-outline"></ion-icon>
          </button>
        </div>
      </div>

      <div class="metadata-row">
        <ion-note color="primary" *ngIf="selectedPOI?.category">
          <i class="fas {{ selectedPOI.category.icon }}"></i>
          {{ selectedPOI.category.name[languageCode] }}
        </ion-note>
        <div *ngIf="selectedPOI?.occupancyLevel" class="occupancy">
          <div class="occupancy-button">
            <span>{{ 'campus-navi.occupancy.occupancy' | translate }}</span>
            <span class="{{ selectedPOI.occupancyLevel.toLowerCase() }}">{{ 'campus-navi.occupancy.' + selectedPOI.occupancyLevel.toLowerCase() | translate }}</span>
          </div>
          <div class="occupancy-level {{ selectedPOI.occupancyLevel.toLowerCase() }}"></div>
        </div>
      </div>
      <h1 *ngIf="selectedPOI?.name">{{ selectedPOI.name[languageCode] }}</h1>
      <p *ngIf="selectedPOI?.description" [innerHTML]="selectedPOI.description[languageCode]"></p>

      <ol class="poi-list">
        <li *ngFor="let room of selectedPOI?.rooms">{{ room.floor.name }}: {{ room.roomNo }}</li>
      </ol>
    </ion-content>
  </ng-template>
</ion-modal>