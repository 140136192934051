import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { authenticator } from '@otplib/preset-browser';
import { environment } from 'src/environments/environment';
import { AUTHENTICATED } from '../../services/oauth2/authentication.interceptor';

export interface TOTPValidation {
  valid: boolean;
}

const API_URL = environment.apiUrl;
const API_SERVICE = `${API_URL}/campus-id/v1`;
const API_TOTP_VALIDATE = `${API_SERVICE}/totp/valid`;
const API_TOTP_SECRET = `${API_SERVICE}/totp/secret`;

@Injectable({
  providedIn: 'root',
})
export class CampusIdService {
  constructor(private http: HttpClient) {}

  static createQueryParams(params: any): string {
    return Object.keys(params)
      .map((key: string) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&');
  }

  static generateQRCodeString(uuid: string, totp: string): string {
    return `https://tu-dortmund.de/app/campusID/auth?${this.createQueryParams({ uuid, totp })}`;
  }

  static generateTOTP(secret: string): string {
    return authenticator.generate(secret);
  }

  public validate(uuid: string, totp: string): Observable<TOTPValidation> {
    return this.http.get<TOTPValidation>(API_TOTP_VALIDATE, {
      context: AUTHENTICATED,
      params: { uuid, totp },
    });
  }

  public requestTOTP(): Observable<{ uuid: string; secret: string }> {
    return this.http.get<{ uuid: string; secret: string }>(API_TOTP_SECRET, {
      context: AUTHENTICATED,
    });
  }
}
