import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

export const VERSIONS = [
  {
    version: '5.4.0',
    date: '07. Februar 2025',
    description: `
    <strong>Gesundheitspass</strong>
    <br/><br/>
    Der Gesundheitspass für Beschäftigte ist nun auch digital verfügbar: Ab sofort können Sie für die Teilnahme an Gesundheitsangeboten in der TU Dortmund-App Stempel sammeln. Diese Neuerung ermöglicht es Ihnen, den Gesundheitspass bequem und sicher auf dem Smartphone zu verwalten.
    `,
    changes: [
      'Die Übersicht über den UB-Account wurde an die aktuelle Version des Katalog+ angepasst.',
      'Bugfixes und Verbesserungen'
    ]
  },
  {
    version: '5.2.0',
    date: '1. August 2024',
    description: `
    <strong>Campus-Wetter</strong>
    <br/><br/>
    Lieber Pulli oder T-Shirt? Die TU Dortmund-App zeigt dir jetzt das Campus-Wetter. Die Anzeige kannst du dir so einrichten, wie du es bevorzugst.
    `,
  },
  {
    version: '5.1.0',
    date: '29. Januar 2024',
    description: `
    <strong>Was gibt es Neues für dich in der App?</strong>
    <ul>
      <li>
        Es gibt jetzt eine Kachel, auf der du alle Lernorte am Campus Nord, Süd und in der Innenstadt siehst. Bei ausgewählten Lernorten kannst du sehen, wie ausgelastet sie sind.
      </li>
      <li>
        Wir haben das Campusnavi komplett renoviert:
        <ul>
          <li>Du kannst jetzt stufenlos zoomen und drehen</li>
          <li>Die Darstellung der Karte ist jetzt übersichtlicher</li>
          <li>Du kannst die Karte mit einer Wischgeste (mit zwei Fingern nach oben wischen) kippen</li>
          <li>Auch hier werden die Lernorte mit Auslastung angezeigt</li>
        </ul>
      </li>
      <li>Und wie immer: kleinere Verbesserungen und Bugfixes</li>
    </ul>
    `,
  },
  {
    version: '5.0.0',
    date: '22. November 2023',
    changes: [
      'Bugfixes und Verbesserungen'
    ]
  },
  {
    version: '4.13.0',
    date: '4. Oktober 2023',
    changes: [
      `Wir vereinfachen die WLAN-Konfiguration. Du kannst jetzt dein WLAN-Passwort in der App erneuern und direkt ins Clipboard übernehmen. 
      Genauso funktioniert das auch mit deinem Account für Eduroam.`,
      'Der Lageplan der Bücher zeigt jetzt die Bücher in der Bereichsbibliothek der EF50'
    ]
  },
  {
    version: '4.12.0',
    date: '8. August 2023',
    changes: [
      'Neuigkeiten können durchsucht und nach ungelesen gefiltert werden',
      'Bugfixes und Verbesserungen'
    ]
  },
  {
    version: '4.11.0',
    date: '1. August 2023',
    description: `
      <strong>„Meine Daten”</strong>
      <br><br>
      Nur für Mitarbeitende: „Meine Daten” gibt es jetzt auch in der App. Du kannst dort deine weitere Kontaktdaten hinzufügen
      und dein Profilfoto nun auch mit der Handykamera aufnehmen oder aus deiner Fotogalerie hochladen.
    `,
    changes: [
      'Bugfixes und Verbesserungen'
    ]
  },
  {
    version: '4.10.1',
    date: '17. Februar 2023',
    changes: ['Bugfixes und Verbesserungen'],
  },
  {
    version: '4.10.0',
    date: '6. Februar 2023',
    changes: [
      'Neu: In der Ansicht für das NRW-Ticket gibt es ab jetzt auch die Möglichkeit nur den zu scannenden Code anzuzeigen',
      'Kleinere Fehlerbehebungen und Verbesserungen'
    ]
  },
  {
    version: '4.9.3',
    date: '30. September 2022',
    changes: [
      'Kleinere Fehlerbehebungen und Verbesserungen in der Oberfläche'
    ]
  },
  {
    version: '4.9.2',
    date: '25. April 2022',
    changes: [
      'Verbesserung der Filteroptionen für Veranstaltungen'
    ]
  },
  {
    version: '4.9.1',
    date: '11. April 2022',
    changes: [
      '2G Status wurde entfernt',
      'Suchen in der Personensuche funktioniert nun auch wieder für Nutzer die nicht angemeldet sind',
      `Bei der Anmeldung im persönlichen Bereich führen mögliche Probleme zu differenzierteren 
      Fehlermeldungen (z.B. bei falschen Eingaben oder eine Störung der IT)`,
      'Verbesserung der Stabilität und Fehlerbehebungen'
    ]
  },
  {
    version: '4.9.0',
    date: '10. März 2022',
    description: 'Es ist eine neue Kachel mit den wichtigsten Notfall-Rufnummern hinzugefügt worden.'
  },
  {
    version: '4.8.0',
    date: '1. März 2022',
    description: `<strong>Einführung digitaler Studierendenausweis (Student ID)</strong>
      <br><br>
      Die neue Kachel „Student ID“ im persönlichen Bereich ersetzt den alten Studierendenausweis in Form der UniCard und kann im 
      Wesentlichen für die gleichen Dienste wie die bisherige Scheckkarte genutzt werden: Sie können sich mit der Student ID wie 
      gehabt ausweisen, um beispielsweise den vergünstigten Tarif in der Mensa in Anspruch zu nehmen oder Ermäßigungen außerhalb des 
      Campus (z.B. im Theater) zu nutzen. Auch die Medienausleihe in den Universitätsbibliotheken (UB) ist mit der Student ID möglich.
    `,
  },
  {
    version: '4.7.6',
    date: '14. Februar 2022',
    changes: ['Verhindert mögliche ungewollte Abmeldungen', 'Verbesserung der Stabilität und Fehlerbehebungen'],
  },
  {
    version: '4.7.4',
    date: '3. Dezember 2021',
    changes: ['Der Zugriff auf den 2G-Nachweis ist nun direkt über die Startseite möglich'],
  },
  {
    version: '4.7.3',
    date: '8. November 2021',
    changes: ['Behebt ein Problem bei dem es zur Abmeldung des UniAccounts kam', 'Verbesserung der Stabilität und Fehlerbehebungen'],
  },
  {
    version: '4.7.2',
    date: '6. Oktober 2021',
    changes: ['Behebt ein Problem bei dem der 3G-Status nicht angezeigt werden konnte'],
  },
  {
    version: '4.7.1',
    date: '1. Oktober 2021',
    changes: ['"Test-Check In" und "Platz scannen" wurde entfernt'],
  },
  {
    version: '4.7.0',
    date: '21. September 2021',
    changes: [
      `Es wird jetzt in campusID angezeigt, ob du geimpft, genesen oder getestet bist (3G-Status). Tippe dazu in campusID 
      auf das Corona-Zeichen. Bevor du ein Gebäude auf dem Campus betrittst, lass deinen 3G-Status bitte an einem der Check-Ins 
      auf dem Campus feststellen. Die sind am Campus ausgeschildert.`,
      'Kleinere Fehlerbehebungen und Verbesserungen in der Oberfläche',
    ],
  },
  {
    version: '4.6.0',
    date: '20. August 2021',
    changes: ['Verbesserung der Stabilität und Fehlerbehebungen'],
  },
  {
    version: '4.5.0',
    date: '28. April 2021',
    description: `<strong>Neue Funktion: „Test-Check In”</strong>
                  <br><br>
                  Wer sich in einem Corona-Testzentrum der TU Dortmund testen lässt, kann sich hierüber einfach und bequem einchecken. 
                  Nach Eingabe von Name, Adresse und Telefonnummer wird ein Barcode erzeugt, der bei der Registrierung im Testzentrum 
                  eingelesen wird.`,
  },
  {
    version: '4.4.0',
    date: '12. April 2021',
    description: `<strong>Neue Funktion zur digitalen Identitätsübermittlung: campusID</strong>
                  <br><br>
                  Studierende und Mitarbeitende können jetzt einfach ihre digitale Identität bestätigen. Damit werden die digitalen 
                  Prozesse auf dem Campus beschleunigt. Mit campusID kann man sich zukünftig an den Coronateststellen der 
                  TU Dortmund anmelden.`,
    changes: ['News Quelle "Stabsstelle Chancengleichheit, Familie und Vielfalt" hinzugefügt', 'Bugfixes und Verbesserungen'],
  },
  {
    version: '4.3.0',
    date: '6. Januar 2021',
    changes: ['Bugfixes und Verbesserungen'],
  },
  {
    version: '4.2.0',
    date: '28. September 2020',
    description: `<strong>Neue Funktion zur Angabe des Aufenthaltsorts auf dem Campus</strong>
                  <br><br>
                  Über eine neue Funktion in der TU-App können Studierende ab sofort ihren Aufenthaltsort und eine 
                  Kontaktmöglichkeit eingeben, wenn sie sich z.B. in einer Vorlesung oder einem Seminar befinden. 
                  Sollte es Corona-Fälle im Umfeld einer Lehrveranstaltung geben, können so mögliche Kontaktpersonen 
                  ausfindig gemacht und informiert werden.`,
    changes: [
      'Ein- und Auschecken am Sitzplatz in Hörsälen, Seminarräumen, Lernräumen und Universitätsbibliothek',
      'Einchecken durch einfaches Scannen eines QR-Codes, der an jeden Sitzplatz angebracht ist',
      'Auschecken durch erneutes Scannen des QR-Codes',
      'Kontaktdaten werden nur beim erstmaligen Aufrufen eingegeben',
      'Aufenthaltsdaten werden in einem separaten System gespeichert und nach vier Wochen gelöscht',
      `Daten werden streng vertraulich behandelt und dienen einzig der gesetzlich vorgeschriebenen 
      Erfassung von Personendaten in Lehrveranstaltungen`,
    ],
  },
  {
    version: '4.1.0',
    date: '18. August 2020',
    changes: [
      'Wechsel zwischen der Startseite und dem persönlichen Bereich jetzt per Wischgeste möglich',
      'verbesserte Darstellung der Wochentage und Öffnungszeiten im Mensaplan',
      'Bugfixes und sonstige Verbesserungen',
    ],
  },
  {
    version: '4.0',
    date: '27. Januar 2020',
    description: 'Mit Piktogrammen und Öffnungszeiten: Neue Version der TU Dortmund-App bietet detaillierteren Mensaplan an.',
    changes: [
      `Seit heute steht die neue Version der TU Dortmund-App zum Download bereit.
      Neben einer Optimierung des Datenverbrauchs zeichnet sich die Version 4.0
      auch durch eine Verbesserung der Stabilität und Ladezeiten innerhalb der App aus.`,
      `Der Mensaplan ist nun mit Piktogrammen versehen, die anzeigen, ob ein Gericht vegetarisch, vegan,
      mit Fleisch oder mit Fisch zubereitet wird. Ob es zu dem Gericht einen Kinderteller gibt,
      wird ebenfalls durch ein Piktogramm verdeutlicht.
      Zudem können die Zusatzstoffe der Gerichte nun ganz einfach durch einen Klick auf das Gericht aufgerufen werden.
      Die Öffnungszeiten der Mensen werden ebenfalls angezeigt.`,
      `Die Farben in der App wurden für einen besseren Kontrast angepasst.
      So ist die App fortan leichter zu lesen und damit barriereärmer.`,
      'Wenn in der Personensuche Webseiten verlinkt sind, können diese nun direkt in der App aufgerufen werden.',
    ],
  },
];

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.page.html',
})
export class ChangelogPage {
  public versions = VERSIONS;

  constructor(public modalController: ModalController) {}
}
