import { ResetTiles, AddTiles } from './tile.actions';
import { DynamicTile, DynamicTileState } from './tile.service';
import { createReducer, Action, on, createFeatureSelector, createSelector } from '@ngrx/store';

export const tileFeatureKey = 'tile';

export interface TilesState {
  tiles: DynamicTile[];
}

export const initialState: TilesState = {
  tiles: []
};

const tileReducer = createReducer<TilesState, Action>(
  initialState,
  on(ResetTiles, () => initialState),
  on(AddTiles, (state: TilesState, action: { tiles: DynamicTile[] }) => ({ ...state, tiles: action.tiles }))
);

export const reducer = (state: TilesState | undefined, action: Action) => tileReducer(state, action);

export const selectTile = createFeatureSelector<TilesState>(tileFeatureKey);
export const getAllTiles = createSelector(selectTile, (state: TilesState) => state.tiles);
export const getAllActiveTiles = createSelector(selectTile, (state: TilesState) => {
  const filteredTiles = state.tiles.filter((tile: DynamicTile) => {
    const inPeriod = new Date() >= tile.start && new Date() <= tile.end;
    const isActive = tile.state === DynamicTileState.ACTIVE;
    const isPreview = tile.state === DynamicTileState.PREVIEW;
    return (isActive && inPeriod) || isPreview;
  });
  return filteredTiles;
});
