import { HttpContext, HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { App, AppState } from '@capacitor/app';
import { PluginListenerHandle } from '@capacitor/core';

export const AUTHENTICATE = new HttpContextToken<boolean>(() => false);
export const AUTHENTICATED = new HttpContext().set(AUTHENTICATE, true);

@Injectable({
  providedIn: 'root'
})
export class AuthenticationInterceptor implements HttpInterceptor {
  private stageChangeListener: PluginListenerHandle | null = null;
  private isBackgroundMode = false;

  constructor(
    private authenticationService: AuthenticationService
  ) {
    if(!this.stageChangeListener) {
      App.addListener('appStateChange', this.stageChanged).then(listener => {
        this.stageChangeListener = listener;
      });
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(this.isBackgroundMode) {
      console.error("App is in background Mode. No requests will be processed.");
      return;
    }
    
    if (request.context.get(AUTHENTICATE)) {
      return this.authenticationService.handleAuthenticatedRequest(request, next);
    }

    // Handle request that doesn't require authentication
    return next.handle(request);
  }

  private stageChanged(state: AppState) {
    this.isBackgroundMode = !state.isActive;
  }
}
