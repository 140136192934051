import { Component, Injector, Input, OnInit } from '@angular/core';
import { IonRouterOutlet, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-campus-navi',
  templateUrl: './campus-navi.page.html',
  styleUrls: ['./campus-navi.page.scss']
})
export class CampusNaviPage implements OnInit {
  constructor(
    public modalController: ModalController,
    private injector: Injector,
  ) {}

  @Input()
  public enabledCategories: number[] = [0, 1, 2, 3, 4, 6, 7, 8, 9, 10, 11];

  @Input()
  public showCategories = true;

  @Input()
  public trackUserLocation = true;

  @Input()
  public isModal = false;

  async ngOnInit() {
    if(!this.isModal) {
      const routerOutlet = this.injector.get(IonRouterOutlet);
      routerOutlet.swipeGesture = false;
    }
  }

  public async ionViewWillLeave() {
    this.modalController.dismiss();
  }
}
