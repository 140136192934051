import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { IRoom } from './conjectfm.service';

export interface IPOICategory {
  id: number;
  icon: string;
  color?: string;
  name: {
    de: string;
    en: string;
  };
}

export interface IPOI {
  id: string;
  name: {
    de: string;
    en: string;
  };
  description: {
    de: string;
    en: string;
  };
  roomId?: number;
  categoryId: number;
  gps: {
    latitude: number;
    longitude: number;
  } | null;
  occupancyLevel?: 'LOW' | 'MEDIUM' | 'HIGH';

  // optional and specifically used in map routing
  currentPosition?: boolean;

  // this attribute is here for convinience since room information are
  // used in conjunction with the POI (see `updatePOIs()` in `campus-navi.page.ts`)
  room?: IRoom;
}

const API_URL = environment.apiUrl;
const API_SERVICE_URL = `${API_URL}/points-of-interest/v4`;
const API_CATEGORIES = `${API_SERVICE_URL}/categories`;
const API_POI = `${API_SERVICE_URL}/points-of-interest`;
const API_BUILDINGS = `${API_SERVICE_URL}/all`;

@Injectable({
  providedIn: 'root'
})
export class POIService {

  constructor(private http: HttpClient, private translate: TranslateService) { }

  public buildings(): Observable<IPOI[]> {
    return this.http.get<IPOI[]>(API_BUILDINGS)
      .pipe(map(buildings => buildings.filter(building => !building.gps ? false : true)));
  }

  public categories(): Observable<IPOICategory[]> {
    return this.http.get<IPOICategory[]>(API_CATEGORIES).pipe(map((categories) => {
      return [...categories, { id: 0, icon: 'fa-tag', name: { de: 'Sonstige', en: 'Other' } }];
    }));
  }

  public pois(): Observable<IPOI[]> {
    return this.http.get<IPOI[]>(API_POI);
  }

  public poisByCategory(category: number): Observable<IPOI[]> {
    return this.http.get<IPOI[]>(`${API_BUILDINGS}?categoryId=${category}`);
  }

  public getLocale(): string {
    switch (this.translate.currentLang) {
      case 'de-DE': {
        return 'de';
      }

      case 'en-US': {
        return 'en';
      }

      default: {
        break;
      }
    }
  }

  /**
   * This function turns a list of `IPOICategory`s into a map of `IPOICategory` and `IPOIBuilding[]`.
   *
   * Here is an example:
   *
   * Given the following input param:
   *
   * [{
   *   id: 0,
   *   icon: "fa-cutlery",
   *   color: "green",
   *   name: { de: "Test", en: "Test" }
   * }]
   *
   * The result will be as follows:
   *
   * {
   *   0: {
   *     category: {
   *       id: 0,
   *       icon: "fa-cutlery",
   *       color: "green",
   *       name: { de: "Test", en: "Test" }
   *     },
   *     pois: [{
   *       id: 'fffffffffffffffff',
   *       categoryId: 0,
   *       name: { de: "POI", en" "POI" },
   *       description: { de: "", en: "" },
   *       gps: {
   *         latitude: 51.00,
   *         longitude: 7.41
   *       }
   *     }]
   *   }
   * }
   *
   * Note that the root key (0 in this example) corresponds to the `categoryId`.
   *
   */
  public categoryPOIMap(categories: IPOICategory[]): Observable<{ [key: number]: { category: IPOICategory; pois: IPOI[] }}> {
    const poisSubjects = categories.reduce((previous, current: IPOICategory) => ({
      ...previous,
      [current.id]: this.poisByCategory(current.id)
    }), {});

    return forkJoin(poisSubjects).pipe(map((value: { [key: number]: IPOI[] }) => {
      let newMap = {};
      for(const categoryId of Object.keys(value).map(Number)) {
        const category = categories.find((cat) => cat.id === categoryId);
        newMap = {
          ...newMap,
          [categoryId]: {
            category,
            pois: value[categoryId]
          }
        };
      }
      return newMap;
    }));
  }

  public static sortRoomByFloorAndRoomNo(a: IRoom, b: IRoom): number {
    if(a.floor.name === b.floor.name) {
      if(a.roomNo < b.roomNo) {
        return -1;
      } else if(a.roomNo > b.roomNo) {
        return 1;
      } else {
        return 0;
      }
    } else if(a.floor.name < b.floor.name) {
      return -1
    } else {
      return 1;
    }
  }
}
