import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IEmployee, IOrganizationNumbers, IOrganization, EmployeeService, IEmployeeNumber } from '../../../services/employee.service';
import { TranslateService } from '@ngx-translate/core';
import { Browser } from '@capacitor/browser';
import { ContactInput, Contacts, EmailType, PhoneType } from '@capacitor-community/contacts';
import { AlertController, ModalController } from '@ionic/angular';
import { map, of } from 'rxjs';
import { Pageable } from 'src/app/common';

@Component({
  selector: 'app-employee-detail',
  templateUrl: './employee-detail.page.html',
  styleUrls: ['./employee-detail.page.scss'],
})
export class EmployeeDetailPage implements OnInit {

  @Input()
  public employee: IEmployee | null = null;
  public organizationNumbers: IOrganizationNumbers = {};
  public organizations: IOrganization[] = [];
  public avatar: string | ArrayBuffer;
  public isModal = false;

  constructor(
    private route: ActivatedRoute,
    private employeeDataService: EmployeeService,
    public translate: TranslateService,
    public alertController: AlertController,
    public modalController: ModalController
  ) {}

  ngOnInit() {
    if(this.employee === null) {
      this.employee = this.route.snapshot.data.employeeDetails.employee;
      this.avatar = this.route.snapshot.data.employeeDetails.avatar;
      this.organizationNumbers = this.route.snapshot.data.employeeDetails.numbers;
      this.organizations = this.route.snapshot.data.employeeDetails.organizations;
    } else {
      this.isModal = true;
      this.route.snapshot.paramMap.get('id');

      this.employeeDataService.getAvatar(this.employee.tudoid).subscribe((avatar: Blob) => {
        const reader = new FileReader();
        reader.onload = () => {
          this.avatar = reader.result;
        };
        reader.readAsDataURL(avatar);
      }, () => {
        this.avatar = 'assets/employee-search/portrait.svg';
      });

      this.employeeDataService.getNumbersFrom(this.employee.tudoid).pipe(
        map((result: Pageable<IEmployeeNumber[]>) => {
          const organizationNumbers: IOrganizationNumbers = {};
          (result.data as IEmployeeNumber[]).forEach((element: IEmployeeNumber) => {
            const organizationName = element.organizationName == null ? 'other' : element.organizationName;
            if (!organizationNumbers[organizationName]) {
              organizationNumbers[organizationName] = [];
            }
            organizationNumbers[organizationName].push(element);
          });
          return organizationNumbers;
        })
      ).subscribe((organizationNumbers) => {
        this.organizationNumbers = organizationNumbers;
      });

      this.employee.organizationIds.length ? this.employeeDataService.getOrganizations(this.employee.organizationIds) : of([]).subscribe((ous) => {
        this.organizations = ous;
      }) 
    }
  }

  public openURL(url: string) {
    Browser.open({ url });
  }

  private async createContact() {
    const contact: ContactInput = {
      name: {
        given: this.employee.forename,
        family: this.employee.surname
      }
    };

    if(this.employee.email) {
      contact['emails'] = [{
        type: EmailType.Work,
        label: 'work',
        address: this.employee.email,
        isPrimary: true
      }]
    }

    contact['phones'] = [];
    if(this.employee.primaryPhoneNumber) {
      contact['phones'].push({
        type: PhoneType.Work,
        label: 'work',
        number: this.employee.primaryPhoneNumber,
        isPrimary: true
      });
    }

    for(const organization in this.organizationNumbers) {
      for(const organizationNumber of this.organizationNumbers[organization]) {
        if(contact.phones.findIndex((phone) => phone.number === organizationNumber.e164Num) !== -1) {
          // skip numbers that are already added
          continue;
        }

        switch(organizationNumber.type) {
          case 'fax':
            contact['phones'].push({
              type: PhoneType.FaxWork,
              label: await this.translate.instant('employee-data.facsimile'),
              number: organizationNumber.e164Num,
            });
            break;
          case 'sek':
            contact['phones'].push({
              type: PhoneType.Assistant,
              label: await this.translate.instant('employee-data.administration'),
              number: organizationNumber.e164Num,
            });
            break;
          case 'ext':
            contact['phones'].push({
              type: PhoneType.Work,
              label: await this.translate.instant('employee-data.telephone'),
              number: organizationNumber.e164Num,
            });
            break;
        }
      }
    }

    for(const organization of this.organizations) {
      contact['organization'] = {
        company: 'Technische Universität Dortmund',
        department: organization.departmentDescription
      }
      // Only one organization can be added
      break;
    }

    // This is old code for attaching a profile picture to a contact. Unfortunately this feature is no longer supported. Just in case
    // this feature comes back at some point.
    //
    // The feature request is getting tracked here: https://github.com/capacitor-community/contacts/issues/59
    //
    // this.employeeSearchService
    //   .getAvatar(this.employee.tudoid)
    //   .subscribe((avatar: Blob) => {
    //     contact.photos = [
    //       new ContactField('base64', window.URL.createObjectURL(avatar))
    //     ];
    //   });

    return contact;
  }

  public async addToContacts() {
    await Contacts.requestPermissions();
    const permissionStatus = await Contacts.checkPermissions();
    if(permissionStatus.contacts === 'granted') {
      const contact = await this.createContact();
      Contacts.createContact({ contact }).then(async (_) => {
        const alert = await this.alertController.create({
          header: this.translate.instant('employee-search.contactAlert.header'),
          message: this.translate.instant('employee-search.contactAlert.message', { employee: this.employee.displayName }),
          buttons: ['Ok']
        });
        alert.present();
      });
    } else {
      const alert = await this.alertController.create({
        header: this.translate.instant('employee-search.contactPermissionDeniedAlert.header'),
        message: this.translate.instant('employee-search.contactPermissionDeniedAlert.message'),
        buttons: ['Ok']
      });
      alert.present();
    }
  }
}
