import * as fromSettings from './ngrx/settings/settings.reducer';
import * as fromNews from './ngrx/news/news.reducer';
import * as fromTile from './ngrx/tile/tile.reducer';
import * as fromPushNotification from './ngrx/push-notification/push-notification.reducer';
import * as fromCampusId from './ngrx/campus-id/campus-id.reducer';

import { ActionReducer, MetaReducer, Action, createFeatureSelector } from '@ngrx/store';
import { of, from, forkJoin, concat } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Hydrate } from './store.actions';
import { Preferences } from '@capacitor/preferences';

export interface AppState {
  settings: fromSettings.SettingsState;
  news: fromNews.NewsState;
  tile: fromTile.TilesState;
  'push-notification': fromPushNotification.PushNotificationState;
  'campus-id': fromCampusId.CampusIdState;
  hydrate: boolean;
}

export const getHydrate = createFeatureSelector<boolean>('hydrate');

export interface StorageReducerOptions {
  features: string[];
}

type ActionReducerFunction = (reducer: ActionReducer<AppState, Action>) => ActionReducer<AppState, Action>;

export const storageReducerFactory = (options: StorageReducerOptions = { features: [] }): ActionReducerFunction => {
  const ignoreActions = [
    '@ngrx/store/init',
    '@ngrx/effects/init',
    '@ngrx/store/update-reducers',
    '@ngrx/store-devtools/recompute',
    Hydrate.type,
  ];

  const hydratedState: { hydrate: boolean } = { hydrate: false };

  return (reducer: ActionReducer<AppState, Action>): ActionReducer<AppState, Action> => (state: AppState, action: Action & any) => {
      const { type, ...payload } = action;

      if (type === Hydrate.type) {
        // Combine state from payload with the current state for each feature state
        for (const featureKey of options.features) {
          const featureState = {
            ...state[featureKey],
            ...payload.state[featureKey],
          };
          state = {
            ...state,
            [featureKey]: featureState,
          };
        }
        hydratedState.hydrate = true;
      }

      if(!state.hydrate && !hydratedState.hydrate) {
        return state;
      }

      const nextState = {
        ...reducer(state, action),
        ...hydratedState,
      };

      // Basically this will execute on every action after the Hydrate action
      if (ignoreActions.indexOf(action.type) === -1) {
        const saveList = [];
        for (const featureKey of options.features) {
          saveList.push(of(save(featureKey, nextState[featureKey])));
        }
        concat(saveList).subscribe({
          next: () => {
            console.info('[ngrx] Saved state to storage');
          },
          error: (error) => {
            console.error('[ngrx] Error while saving to storage', action.type, error);
          }
        });
      }
      return nextState;
    };
};

// Defines what feature state should be stored. Should be one of the keys in AppState
export const storedFeatureKeys = [
  fromSettings.settingsFeatureKey,
  fromNews.newsFeatureKey,
  fromTile.tileFeatureKey,
  fromPushNotification.pushNotificationFeatureKey,
  fromCampusId.campusIdFeatureKey,
];

export const storageReducer = storageReducerFactory({
  features: storedFeatureKeys,
});

export const storageMetaReducer = (reducer: ActionReducer<AppState, Action>) => storageReducer(reducer);
export const metaReducers: MetaReducer<any>[] = [storageMetaReducer];

/**
 * Store the payload of an Action by the given key.
 *
 * @param key defines the key under which the payload is stored
 * @param state state that will be saved
 */
export const save = (key: string, state: any) => Preferences.set({
  key,
  value: JSON.stringify(state),
});

const initialStateForFeature = (key: string) => {
  switch (key) {
    case fromSettings.settingsFeatureKey:
      return fromSettings.initialState;
    case fromNews.newsFeatureKey:
      return fromNews.initialState;
    case fromTile.tileFeatureKey:
      return fromTile.initialState;
    case fromPushNotification.pushNotificationFeatureKey:
      return fromPushNotification.initialState;
    case fromCampusId.campusIdFeatureKey:
      return fromCampusId.initialState;
    default:
      console.warn(`Unknown feature key ${key}`);
      break;
  }
  return null;
};

/**
 * Load and parse the saved state into an object that should match the AppState.
 *
 * @param featureKeys defines what feature states should be loaded from storage
 */
export const load = (featureKeys: string[]) => {
  const storageMap = {};
  for (const key of featureKeys) {
    storageMap[key] = from(Preferences.get({ key }));
  }

  return forkJoin(storageMap).pipe(
    catchError(() => of({})),
    map((state: { [key: string]: { value: string } }) => {
      const reconstructedState: Partial<AppState> = {};
      for (const featureKey of featureKeys) {
        if (!state[featureKey] || state[featureKey].value === null) {
          reconstructedState[featureKey] = initialStateForFeature(featureKey);
          continue;
        }
        reconstructedState[featureKey] = JSON.parse(state[featureKey].value);
      }
      return reconstructedState;
    })
  );
};
