import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, Action, on, createFeatureSelector, createSelector } from '@ngrx/store';
import { CampusIdFailed, CampusIdReset, CampusIdStore } from './campus-id.actions';
import { CampusIdService } from './campus-id.service';

export const campusIdFeatureKey = 'campus-id';

export interface CampusIdState {
  uuid: string;
  key: string;
}

export const initialState: CampusIdState = {
  uuid: null,
  key: null
};

const campusIdReducer = createReducer<CampusIdState, Action>(
  initialState,
  on(CampusIdReset, () => ({ ...initialState })),
  on(CampusIdStore, (state: CampusIdState, payload: { secret: string; uuid: string }) => ({
      ...state,
      uuid: payload.uuid,
      key: payload.secret
    })),
  on(CampusIdFailed, (_: CampusIdState, payload: { error: HttpErrorResponse }) => ({ ...initialState }))
);

export const reducer = (state: CampusIdState | undefined, action: Action) => campusIdReducer(state, action);

export const selectCampusId = createFeatureSelector<CampusIdState>(campusIdFeatureKey);

export const selectCampusIdState = createSelector(selectCampusId, (state: CampusIdState) => state);

export const selectQRCodeContent = createSelector(selectCampusId, (state: CampusIdState) => {
  if (!state.key || !state.uuid) {
    return null;
  }
  const totp = CampusIdService.generateTOTP(state.key);
  return CampusIdService.generateQRCodeString(state.uuid, totp);
});
